import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import blogPostURLProcess from '../utils/blog-post-url-process';
import removeTrailingSlash from '../utils/remove-trailing-slash';

import loadable from '@loadable/component';

import '../styles/blog-list.scss';

const Grid = loadable(() => import('../components/grid'));

const BlogList = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  const metaData = {
    title: 'Blog'
  };

  return (
    <Layout metaData={metaData}>
      <Grid>
        {posts.map(({ node }) => (
          <article key={node.id} className="post-preview">
            <Link className="link-wrapper" to={removeTrailingSlash(blogPostURLProcess(node.fields.slug))}>
              <h2 className="title">{node.frontmatter.title}</h2>
            </Link>
            {node.frontmatter.featuredImage ? (
              <Link className="link-wrapper" to={removeTrailingSlash(blogPostURLProcess(node.fields.slug))}>
                <Img
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  title={node.frontmatter.title}
                  alt={node.frontmatter.title}
                  loading="eager"
                  fadeIn={false}
                ></Img>
              </Link>
            ) : null}
            {node.frontmatter.excerpt && (
              <Link className="link-wrapper" to={removeTrailingSlash(blogPostURLProcess(node.fields.slug))}>
                <p className="excerpt">{node.frontmatter.excerpt}</p>
              </Link>
            )}
          </article>
        ))}
      </Grid>
    </Layout>
  );
};

export default BlogList;

export const blogList = graphql`
  query blogList {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/blog/" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            title
            excerpt
            featuredImage {
              ...mediumImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
